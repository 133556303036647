import React, { useState, useEffect, useRef } from "react";
import { useSDK } from "@metamask/sdk-react";
import axios from 'axios';


const App = () => {

  const [accounts, setAccount] = useState("");
  const [claimedNFT, setClaimedNFT] = useState(0);
  const [loading, setLoading] = useState(false);
  const { sdk, account, extensionActive } = useSDK();
  const [step, setStep] = useState(1);
  const [userNFT, setUserNFT] = useState({
    id: "#",
    name: "OneTo11 Champions",
    image: "images/default.png",
    description: ""
  });


  const [otp, setOtp] = useState(['', '', '', '', '', '']); // State to store OTP characters
  const inputRefs = useRef([]); // Ref to store references to input elements

  const [token, setToken] = useState("");

  const [credentials, setCredentials] = useState({
    email: "", password: "", confirmPassword: ""
  });

  const onChange = (e) => {
    // console.log(e.target.value);
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };


  const [alert, setAlert] = useState({ msg: "", type: "error" });
  const buttonRef = useRef(null);

  const showAlert = (message, _type) => {
    // console.log(message);
    setAlert({ msg: message, type: _type },
      setTimeout(() => {
        setAlert({ msg: message, type: "error" });
      }, 2000)
    );
  }

  const connectSDK = async () => {
    try {
      const accounts = await sdk?.connect();
      console.log(accounts);
      setAccount(accounts?.[0]);
      buttonRef.current.click();
    } catch (err) {
      console.log("failed to connect..", err);
    }
  };


  useEffect(() => {
    if (extensionActive)
      setAccount(account);
  }, [extensionActive, account])




  useEffect(() => {
    try {
      axios.post("https://metamaskapi.oneto11.com/server/wallet/getNFTSeq")
        .then((res) => {
          // console.log("Claimed",res.data.res.seqId);
          setClaimedNFT(res.data.res.seqId);
        });
    } catch (error) {
      console.log(error);
    }

  })

  // const connectWallet = async () => {
  //   const provider = await detectEthereumProvider();
  //   console.log(JSON.stringify(provider));
  //   if (provider) {
  //     // Request access to MetaMask accounts
  //     try {
  //       const accounts = await provider.request({ method: 'eth_requestAccounts' });
  //       const chainId = await window.ethereum.request({ method: 'eth_chainId' });
  //       if (chainId !== "0x13881") {
  //         showAlert('Please select polygon mumbai network!', "open error");
  //         return;
  //       }
  //       setAccount(accounts[0]);
  //     } catch (error) {
  //       showAlert('User denied accounts access', "open error");
  //     }
  //   } else {
  //     showAlert('Please install MetaMask', "open error");
  //   }
  // }


  /// claim your NFT 
  const claimNFT = async () => {

    if (accounts === "") {
      showAlert("Connect your wallet first", "open error");
      return;
    }

    setLoading(true);
    var data = { "walletAddress": accounts };

    var config = {
      method: 'post',
      url: `https://metamaskapi.oneto11.com/server/wallet/claimNFT`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
      },
      data: data
    };

    await axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.rs !== 1)
          showAlert(response.data.error, "open error");
        else {
          showAlert(response.data.error, "open success");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
    setLoading(false);
  }

  const checkEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const checkValidEmail = (event) => {
    event.preventDefault();
    if (checkEmail(credentials.email)) {
      setStep(2);
    }
    else {
      showAlert("Enter valid email", "open error");
    }
  }

  /// claim your NFT 
  const sendOTP = async (event) => {
    event.preventDefault();
    if (checkEmail(credentials.email)) {
      var data = { "email": credentials.email };

      var config = {
        method: 'post',
        url: `https://metamaskapi.oneto11.com/server/user/signUp`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*'
        },
        data: data
      };

      try {
        await axios(config)
          .then(function (response) {
            console.log(response.data);

            if (response.data.rs !== 1)
              showAlert(response.data.error, "open error");
            else {
              setToken(response.data.res.token);
              setStep(3);
            }

          })
          .catch(function (error) {
            console.log(error);
          });

      } catch (error) {
        console.log(error);
      }
    }
    else {
      showAlert("Enter valid email", "open error");
    }
  }

  // Function to handle changes in OTP input
  const handleChange = (index, event) => {
    const newOtp = [...otp];
    newOtp[index] = event.target.value;
    setOtp(newOtp);

    // Focus next input box if available
    if (index < inputRefs.current.length - 1 && event.target.value !== '') {
      inputRefs.current[index + 1].focus();
    }
  }

  // Function to handle backspace key
  const handleBackspace = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  }

  // Function to handle paste event
  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain').split('');
    let currentIndex = inputRefs.current.findIndex((ref) => ref === document.activeElement);
    const newOtp = [...otp];

    pasteData.forEach((char) => {
      if (currentIndex < newOtp.length) {
        newOtp[currentIndex] = char;
        currentIndex++;
      }
    });
    setOtp(newOtp);
  }

  const verifyOtp = async (event) => {
    event.preventDefault();
    var data = { "otp": otp.join(''), "token": token };
    console.log(data);

    var config = {
      method: 'post',
      url: `https://metamaskapi.oneto11.com/server/user/verifyEmail`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
      },
      data: data
    };

    try {
      await axios(config)
        .then(function (response) {
          console.log(response.data);

          if (response.data.rs !== 1)
            showAlert(response.data.error, "open error");
          else {
            if (response.data.res.walletAdd) {
              buttonRef.current.click();
              setStep(0);
              setAccount(response.data.res.walletAdd);
            }
            else {
              setToken(response.data.res.token);
              setStep(4);
            }

          }

        })
        .catch(function (error) {
          console.log(error);
        });

    } catch (error) {
      console.log(error);
    }
  }


  const createAccount = async (event) => {
    event.preventDefault();

    if (credentials.password.length < 6) {
      showAlert('Password must be 6 digits long', 'open error');
      return;
    }

    if (credentials.password.length > 100) {
      showAlert('Invalid password length', 'open error');
      return;
    }

    if (credentials.password === credentials.confirmPassword) {

      var data = { "password": credentials.password, "token": token };

      console.log(data);

      var config = {
        method: 'post',
        url: `https://metamaskapi.oneto11.com/server/user/updatePassword`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*'
        },
        data: data
      };

      try {
        await axios(config)
          .then(function (response) {
            console.log(response.data);

            if (response.data.rs !== 1)
              showAlert(response.data.error, "open error");
            else {
              buttonRef.current.click();
              setStep(0);
              setAccount(response.data.res.address);
            }

          })
          .catch(function (error) {
            console.log(error);
          });

      } catch (error) {
        console.log(error);
      }
    }
    else {
      showAlert("Passwords do not match", "open error");
    }
  }



  return (
    <>
      <div className="page_wrap">

        <header className="web_head">
          <div className="logo">
            <img src="images/logo_color.png" alt="" />
          </div>
          <div className="connect_btn">
            <button className="btn" {...!accounts && {
              "data-bs-toggle": "modal", "data-bs-backdrop": "static", "data-bs-keyboard": "false",
              "data-bs-target": "#exampleModal"
            }}>{accounts ? accounts.substring(0, 5) + '...' + accounts.substring((accounts.length - 5), accounts.length) : "Connect"}</button>
          </div>
        </header>

        <section className="nft_wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="claim_dtls">
                  <div className="claim_head">
                    <div className="cl_rgt">
                      <span>{userNFT.id}</span>
                      <div className="cl_nme">
                        <h4>{userNFT.name}</h4>
                        {/* <p>{userNFT.description}</p> */}

                      </div>

                    </div>
                    {/* <div className="cl_lft">
                      <p>To Claim <a href="#">Connect Your wallet</a></p>
                    </div> */}
                  </div>
                  <div className="clam_b">
                    <img src={userNFT.image} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="nft_dtls_wrap">
                  <button disabled={loading} className="btn" onClick={claimNFT}>Claim NFT &nbsp;
                    {loading &&
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>}
                  </button>
                  <div className="nft_dtls">
                    <div className="nft_dts_bx">
                      <div className="nft_l">
                        <span>Collection Name</span>
                        <p>OneTo11 Champions</p>
                      </div>
                      <div className="nft_icon">
                        <img src="images/icon.png" alt="" />
                      </div>
                    </div>
                    <div className="nft_dts_bx">
                      <div className="nft_l">
                        <span>Description</span>
                        <p>NFT OneTo11 Champion is an exclusive digital collectible representing the epitome of virtual sporting prowess within the innovative OneTo11 gaming universe. </p>
                      </div>
                      <div className="nft_icon">
                        <img src="images/description.png" alt="" />
                      </div>
                    </div>
                    <div className="nft_dts_bx">
                      <div className="nft_l">
                        <span>TOTAL NFTS</span>
                        <p>100</p>
                      </div>
                      <div className="nft_icon">
                        <img src="images/nft.png" alt="" />
                      </div>
                    </div>
                    <div className="nft_dts_bx">
                      <div className="nft_l">
                        <span>Claimed</span>
                        <p>{claimedNFT}/100</p>
                      </div>
                      <div className="nft_icon">
                        <img src="images/claimed.png" alt="" />
                      </div>
                    </div>
                    <div className="nft_dts_bx">
                      <div className="nft_l">
                        <span>Powered By</span>
                        <p>BNB Greenfield & Polygon</p>
                      </div>
                      <div className="nft_icon dble_img">
                        <img src="images/bnb.png" alt="" />
                        <img src="images/polygon.png" alt="" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className={`alert_msg ${alert.type}`}>
        <div className="error_alert">
          <div className="icon_cross">
            <i className={`ri-${(alert.type === "open error") ? "close" : "checkbox"}-circle-fill`}></i>
          </div>

          <div className="alert_bdy">

            <span>{alert.msg}</span>
          </div>

        </div>
      </div>


      <div className="modal fade connect_modal" id="exampleModal" tabIndex="-1" ariaLabelledby="exampleModalLabel" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button onClick={() => setStep(1)} ref={buttonRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">

              <div className={`connect_m ${step} ${(step === 1) ? "" : "d-none"}`}>
                <div className="conct_head">
                  <img src="images/logo_color.png" alt="" />
                  <h2>Connect to Oneto11</h2>
                </div>

                <div className="cnct_mdl_wrap">

                  <div className="cnct_list" onClick={connectSDK}>
                    <div className="cnct_list_inner">
                      <img src="images/meta.svg" alt="" />
                      <div className="cnct_dta">
                        <h2>MetaMask</h2>
                        <p>Connect to your MetaMask wallet</p>
                      </div>
                    </div>
                  </div>

                  <div className="cnct_list">
                    <div className="cnct_list_inner">
                      <img src="images/wallet.svg" alt="" />
                      <div className="cnct_dta">
                        <h2>WalletConnect</h2>
                        <p>Scan with WalletConnect to connect</p>
                      </div>
                    </div>
                  </div>


                  <div className="cnct_list">
                    <div className="cnct_list_inner">
                      <img src="images/coinbase.svg" alt="" />
                      <div className="cnct_dta">
                        <h2>Coin base</h2>
                        <p>Connect to your Coinbase Wallet</p>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="or_b">
                  <span>or</span>
                </div>
                <div className="w_email">
                  <form>
                    <div className="form-group">
                      <input name="email" onKeyUp={onChange} type="email" className="form-control" placeholder="Continue with E-mail" />
                      <button type="submit" onClick={checkValidEmail} className="submit_btn btn">
                        <i className="ri-arrow-right-line"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className={`sign_up_bx ${(step === 2) ? "" : "d-none"}`}>
                <div className="conct_head">
                  <img src="images/logo_color.png" alt="" />
                  <h2>Create Your Wallet</h2>
                </div>
                <div className="w_email sign_up">
                  <form>
                    <div className="form-group">
                      <input value={credentials.email} disabled type="email" className="form-control" placeholder="Continue with E-mail" />
                      <button type="button" className="submit_btn btn">
                        <i className="ri-checkbox-circle-fill"></i>
                      </button>
                      <span>Signing up will create a new wallet using this email.</span>
                    </div>
                  </form>

                  <div className="sign_upbtn">
                    <button onClick={sendOTP} className="btn">Sign up and Create Wallet</button>
                    <span>By continuing you agree to our Terms & Privacy Policy and Privy's Terms.</span>
                  </div>
                </div>
              </div>

              <div className={`otp_wrap ${(step === 3) ? "" : "d-none"}`}>
                <div className="conct_head">
                  <img src="images/logo_color.png" alt="" />
                  <h2>Enter Code</h2>
                </div>
                <div className="otp_bx">
                  {otp.map((char, index) => (
                    <input className="form-control" key={index}
                      ref={(el) => (inputRefs.current[index] = el)}
                      type="text"
                      value={char}
                      maxLength={1}
                      onChange={(event) => handleChange(index, event)}
                      onKeyDown={(event) => handleBackspace(index, event)}
                      onPaste={handlePaste} />
                  ))}
                </div>
                <div className="e_dtls">
                  <span>A one time authentication code has been sent to <p>{credentials.email}</p></span>
                </div>
                <div className="sign_upbtn pste_btn">
                  <button onClick={verifyOtp} className="btn">Verify</button>

                </div>
              </div>

              <div className={`creat_pass ${(step === 4) ? "" : "d-none"}`}>
                <div className="conct_head">
                  <img src="images/logo_color.png" alt="" />
                  <h2>Create Password</h2>
                </div>
                <div className="w_email cr_p">
                  <form>
                    <div className="form-group">
                      <input name="password" onKeyUp={onChange} type="password" className="form-control" placeholder="Enter Password" />
                    </div>
                    <div className="form-group">
                      <input name="confirmPassword" onKeyUp={onChange} type="password" className="form-control" placeholder="Confirm Password" />
                    </div>
                  </form>

                  <div className="sign_upbtn">
                    <button onClick={createAccount} className="btn">Submit</button>

                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
      </div>



    </>
  );
}

export default App;
